<template>
  <div>
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/downloads' }">Downloads</el-breadcrumb-item>
      </el-breadcrumb>
      <ul class="download-box">
        <h2 class="download-title">Disease and multimorbidity data</h2>
        <el-table :data="downloadUrls"
                  border
                  stripe
                  style = "width: 100%">
          <el-table-column
              label="File"
              style="width:40%">
            <template slot-scope="scope">
              <a :href="scope.row.url" target="_blank" class="tab-column">{{scope.row.name}}</a>
            </template>
          </el-table-column>
          <el-table-column
              prop="description"
              label="Description"
              style="width:60%">
          </el-table-column>
        </el-table>

      </ul>
  </div>
</template>

<script>
export default {
data:() => {
  return {
    downloadUrls: [
      {
        url: 'multimorbidity/excel/Disease_summary_information.csv',
        name:'Disease_summary_information.csv',
        description: 'Disease summary information'
      },
      {
        url: 'multimorbidity/excel/ukb_multimorbidity.csv',
        name:'ukb_multimorbidity.csv',
        description: 'Multimorbidity in UK Biobank',
      },
      {
        url: 'multimorbidity/excel/gene%20interpretable%20multimorbidity.xlsx',
        name:'gene interpretable multimorbidity.xlsx',
        description: 'Gene interpretable multimorbidity',
      },
      {
        url: 'multimorbidity/excel/snp%20interpretable%20multimorbidity.xlsx',
        name:'snp interpretable multimorbidity.xlsx',
        description: 'SNP interpretable multimorbidity',
      },
      {
        url: 'multimorbidity/excel/ppi%20interpretable%20multimorbidity.xlsx',
        name:'ppi interpretable multimorbidity.xlsx',
        description: 'PPI interpretable multimorbidity',
      },
      {
        url: 'multimorbidity/excel/pathway%20interpretable%20multimorbidity.xlsx',
        name:'pathway interpretable multimorbidity.xlsx',
        description: 'Pathway interpretable multimorbidity',
      },
      {
        url: 'multimorbidity/excel/genetic%20correlation%20interpretable%20multimorbidity.xlsx',
        name:'genetic correlation interpretable multimorbidity.xlsx',
        description: 'Genetic correlation interpretable multimorbidity',
      },
      {
        url: 'multimorbidity/excel/disease_snp_sig_ld.txt',
        name:'disease_snp_sig_ld.txt',
        description: 'Disease related SNPs',
      },
      {
        url: 'multimorbidity/excel/disease_gene.txt',
        name:'disease_gene.txt',
        description: 'Disease related genes',
      },
      {
        url: 'multimorbidity/excel/disease_ppi.txt',
        name:'disease_ppi.txt',
        description: 'Disease related PPIs',
      },
      {
        url: 'multimorbidity/excel/disease_pathway.txt',
        name:'disease_pathway.txt',
        description: 'Disease related pathways',
      }
    ]
  }
}
}
</script>

<style lang="less" scoped>
ul.download-box{
  padding: 50px 0;
  width: 50%;
  margin: 0 auto;
  li {
    list-style-type: disc;
  }
  a {
    display: block;
  }
}
.tab-column{
    color: #535558;
  }
  .download-title{
    text-align: left;
    text-shadow: 1px 1px 2px #bbb;
    color: #4e7da9;
  }
</style>
